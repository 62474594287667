import React from 'react';
import Image from '@/components/atoms/Image';
import Heading, {
  HeadingLevels,
} from '@/components/atoms/legacy/Heading/Heading';
import NextLink from 'next/link';
import { BUILD_AND_PRICE, INVENTORY } from '@/lib/constants';
import styles from './HeroSection.module.scss';

const HeroSection = () => {
  return (
    <div className="relative h-[420px] w-full">
      <Image
        className={`object-cover ${styles.heroImage}`}
        src="/images/hero-desktop.png"
        alt="hero_img"
        fill
      ></Image>
      <div className="absolute inset-0 flex flex-col items-center justify-center p-5">
        <div className="mb-1 flex flex-col items-center gap-2">
          <div className="flex gap-5 max-md:gap-3">
            <Heading
              level={HeadingLevels.H3}
              className="text-[78px] text-headline1 font-semibold text-black max-md:text-[32px] max-md:font-medium"
            >
              Everything
            </Heading>
            <Heading
              level={HeadingLevels.H3}
              className="text-[78px] text-headline1 font-extrabold text-black max-md:text-[32px] max-md:font-bold"
            >
              EV
            </Heading>
          </div>
        </div>
        <div className="mb-8 text-center">
          <span className="text-[20px] font-light leading-5 max-md:text-center max-md:text-base max-md:text-galaxy-0">
            Accelerate the transition towards zero emission mobility
          </span>
        </div>
        <NextLink href="/search">
          <button className="m-2 h-auto w-[200px] rounded-md bg-black p-2 px-6 text-base text-white max-md:bg-galaxy-0">
            {INVENTORY}
          </button>
        </NextLink>
        <NextLink href="/build">
          <button className="m-2 h-auto w-[200px] rounded-md bg-black p-2 px-6 text-base text-white max-md:bg-galaxy-0">
            {BUILD_AND_PRICE}
          </button>
        </NextLink>
      </div>
    </div>
  );
};

export default HeroSection;
