import {
  BodyTypeIcon,
  IconBodyType,
} from '@/components/atoms/BodyTypeIcon/BodyTypeIcon';
import React, { FC } from 'react';

const CurrentBodyTypes = [
  { key: IconBodyType.Sedan, label: 'Sedan', value: 'Sedan' },
  { key: IconBodyType.Suv, label: 'SUVs', value: 'SUV' },
  { key: IconBodyType.Hatchback, label: 'Hatchbacks', value: 'Hatchback' },
  { key: IconBodyType.Minivan, label: 'Mini van', value: 'Minivan' },
  { key: IconBodyType.Wagon, label: 'Wagon', value: 'Wagon' },
  { key: IconBodyType.Truck, label: 'Trucks', value: 'Truck' },
];

export const ShopByBodyType: FC<{
  onClick: (bodyType: string) => void;
}> = ({ onClick }) => {
  return (
    <div className="flex flex-col py-3xl m:py-[64px]">
      <div className="flex justify-center pb-xl m:pb-[55px]">
        <div className="text-h2Regular text-neutral-900">Shop by body type</div>
      </div>
      <div className="flex w-full justify-center">
        <div className="grid w-full grid-cols-2 gap-l m:flex m:w-fit-content m:justify-between m:gap-m">
          {CurrentBodyTypes.map((bodyTypeObj, i) => {
            return (
              <div
                key={`${bodyTypeObj.key}-${i}`}
                className="flex justify-center p-l m:py-0"
              >
                <BodyTypeIcon
                  label={bodyTypeObj.label}
                  bodyType={bodyTypeObj.key}
                  aria-label={`search for ${bodyTypeObj.key}`}
                  onClick={() => onClick(bodyTypeObj.value)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
