import { ShopByBodyType } from '@/components/molecules/ShopByBodyType/ShopByBodyType';
import { ShopByPopularMakes } from '@/components/molecules/ShopByPopularMakes/ShopByPopularMakes';
import { useZipContext } from '@/context/ZipContext';
import { handleFilteredSearch } from '@/lib/searchFilters';
import { FC } from 'react';

export const HomePageShopType: FC<{ makes: string[] }> = ({ makes }) => {
  const { userAddress, customZip } = useZipContext();
  const { postal, country } = userAddress || {};

  const handleBodySearch = (bodyType: string) => {
    handleFilteredSearch({
      input: '',
      zip: customZip || postal,
      country,
      body: bodyType,
    });
  };

  const handleMakeSearch = (make: string) => {
    handleFilteredSearch({
      input: '',
      zip: customZip || postal,
      country,
      make,
    });
  };

  return (
    <div className="flex flex-col">
      <ShopByBodyType onClick={handleBodySearch} />
      <ShopByPopularMakes onClick={handleMakeSearch} allMakes={makes} />
    </div>
  );
};
