import Button, { ButtonVariants } from '@/components/atoms/legacy/Button';
import { DropdownMenuGroupType } from '@/components/molecules/DropdownMenu';
import { DropdownMenuItemVariants } from '@/components/molecules/DropdownMenuItem';
import FormSelect from '@/components/molecules/FormSelect';
import { useZipContext } from '@/context/ZipContext';
import { GOOGLE_TRACK_INFO, POPULAR_MAKES } from '@/lib/constants';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { LocationPopOutModal } from '../LocationPopOutModal/LocationPopOutModal';

type HomePageMakeModelsProps = {
  makes: string[];
  models: { [key: string]: string[] };
};

export const HomePageMakeModelsBar: FC<HomePageMakeModelsProps> = (props) => {
  const { makes, models } = props;

  const { userZip, userAddress, customZip, customAddress } = useZipContext();

  const sortedMakes = useMemo(() => {
    const popularMakes: DropdownMenuGroupType = {
      category: 'POPULAR MAKES',
      index: 0,
      menuItems: makes
        .filter((makeName) => POPULAR_MAKES.includes(makeName))
        .sort((a, b) => POPULAR_MAKES.indexOf(a) - POPULAR_MAKES.indexOf(b))
        .map((makeName) => ({ text: makeName, value: makeName })),
    };
    const allMakes: DropdownMenuGroupType = {
      category: 'ALL MAKES',
      index: 1,
      menuItems: makes
        .sort((a, b) => a.localeCompare(b))
        .map((makeName) => ({ text: makeName, value: makeName })),
    };

    return [popularMakes, allMakes];
  }, [makes]);

  const [selectedMakes, setSelectedMakes] = useState<string[]>([]);
  const [modelsDropDown, setModelsDropDown] = useState<DropdownMenuGroupType[]>(
    []
  );
  const [selectedModels, setSelectedModels] = useState<string[]>([]);
  const [searchSlug, setSearchSlug] = useState<string>('');

  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const getModels = useCallback(() => {
    const newModels: DropdownMenuGroupType[] = selectedMakes
      .filter((make) => models[make])
      .map((make) => ({
        category: make,
        index: POPULAR_MAKES.includes(make) ? 0 : 1,
        menuItems: models[make].map((model) => ({
          text: model,
          value: model,
        })),
      }));

    setModelsDropDown(newModels);
  }, [selectedMakes, models]);

  useEffect(() => {
    if (selectedMakes.length > 0) {
      getModels();
    } else {
      setModelsDropDown([]);
    }
  }, [selectedMakes, getModels]);

  useEffect(() => {
    let slug = '';
    if (customZip) {
      slug = `/postal/${customZip}`;
    } else if (userZip && userAddress?.country === 'US') {
      slug = `/postal/${userZip}`;
    }

    if (selectedMakes.length > 0) {
      slug += `/make/${encodeURIComponent(selectedMakes.join(','))}`;
    }
    if (selectedModels.length > 0) {
      slug += `/model/${encodeURIComponent(selectedModels.join(','))}`;
    }
    setSearchSlug(slug);
  }, [selectedMakes, selectedModels, userZip, userAddress, customZip]);

  const handleSetSelectedMakes = useCallback((newSelectedMakes: string[]) => {
    setSelectedMakes(newSelectedMakes);
  }, []);
  const handleSetSelectedModels = useCallback((newSelectedModels: string[]) => {
    setSelectedModels(newSelectedModels);
  }, []);

  return (
    <>
      <div className="flex w-full flex-col gap-m rounded-lg bg-white p-m ml:h-[72px] ml:flex-row">
        <div className="flex h-[48px] flex-grow">
          <FormSelect
            className="flex-grow"
            inputClassName="h-full"
            placeholder="All Makes"
            disabled={sortedMakes?.length === 0}
            showCount={false}
            options={sortedMakes || []}
            value={selectedMakes}
            autoCompleteInput={true}
            onChange={(newSelectedMakes) => {
              handleSetSelectedMakes(newSelectedMakes);
            }}
            variant={DropdownMenuItemVariants.MultiSelect}
            clearMenu={() => handleSetSelectedMakes([])}
          />
        </div>
        <div className="flex h-[48px] flex-grow">
          <FormSelect
            className="w-full"
            inputClassName="h-full"
            placeholder="Select model(s)"
            options={modelsDropDown}
            disabled={modelsDropDown.length === 0}
            value={selectedModels}
            autoCompleteInput={true}
            onChange={(newSelectedModels) =>
              handleSetSelectedModels(newSelectedModels)
            }
            variant={DropdownMenuItemVariants.MultiSelect}
            clearMenu={() => handleSetSelectedModels([])}
          />
        </div>
        <div className="flex h-[48px] w-full ml:h-full ml:w-1/5">
          <Button
            className="w-full whitespace-nowrap"
            variant={ButtonVariants.Primary}
            analyticsEvent={{
              ...GOOGLE_TRACK_INFO.inventoryHomePageButton,
              pageTarget: 'search',
            }}
            onClick={() => {
              window.location.href = `/search/${searchSlug}`;
            }}
          >
            Search EVs
          </Button>
        </div>
      </div>

      <div className="flex w-full justify-between pt-l">
        <div
          className="fit-content flex cursor-pointer items-center"
          onClick={() => setLocationModalOpen(true)}
        >
          <MapPinIcon
            stroke="#FFFFFF"
            className="pr-s"
            width={24}
            height={24}
          />
          <span className="text-body1Medium text-neutral-0">
            {customAddress ||
              `${userAddress?.city ? `${userAddress?.city},` : ''}
              ${userAddress?.state ? `${userAddress?.state},` : ''}
              ${userAddress?.country ? `${userAddress?.country},` : ''}`}
          </span>
          <LocationPopOutModal
            open={locationModalOpen}
            initialZipCode={customZip || userZip}
            shouldStayOrigin={true}
            persistZip={true}
            close={() => {
              setLocationModalOpen(false);
            }}
          />
        </div>
      </div>
    </>
  );
};
