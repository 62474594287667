import React from 'react';
import Image from '@/components/atoms/Image';
import Grid from '@/components/atoms/legacy/Grid';
import { Component } from '@/types/interfaces';
import { ReadyToConnectModal } from './ReadyToConnect';

const LandingPageContact: React.FC<Component> = ({ className = '' }) => {
  return (
    <Grid className={`px-4 py-4 l:gap-x-0 ${className}`}>
      <section className="col-span-full overflow-hidden rounded-t l:col-span-6 l:rounded-l">
        <Image
          className="w-full object-cover"
          src="/images/landing_page_banner_image.jpeg"
          alt="contact us image"
          width="0"
          height="0"
          sizes="100vw"
        />
      </section>
      <ReadyToConnectModal />
    </Grid>
  );
};

export default LandingPageContact;
