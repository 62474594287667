import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import useWindowSize from '@/hooks/useWindowSize';

interface LandingPageBannerProps {
  iconId?: IconIds;
  text: string;
  subtext: string;
  mobileText?: string;
  cta?: string;
  onClickCta?: () => void;
}

const LandingPageBanner: React.FC<LandingPageBannerProps> = ({
  iconId,
  text,
  subtext,
  cta,
  mobileText,
  onClickCta,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const { isDesktop } = useWindowSize();

  // mount the component if the banner has not been previously dismissed
  useEffect(() => {
    const dismissed = sessionStorage.getItem('dismissedBanner');
    setTimeout(() => setIsVisible(!dismissed), 700);
  }, []);

  const handleClickContainerMobile = () => {
    if (!isDesktop && onClickCta) {
      onClickCta();
    }
  };

  return (
    <div
      role="button"
      className={`${
        isVisible ? 'opacity-100' : 'pointer-events-none opacity-0'
      } transition-opacity ${isClosing ? 'duration-200' : 'duration-500'}`}
      onClick={() => handleClickContainerMobile()}
    >
      <div className="relative flex flex-col gap-[8px] bg-gradient-to-r from-brandPrimary to-neutralsGrey-700 px-[24px] py-[16px] ml:flex-row ml:items-center ml:justify-center ml:gap-[12px]">
        <div className="flex items-center gap-[8px] ml:gap-[12px]">
          {iconId && <Icon width={40} height={40} iconId={iconId} />}
          {mobileText ? (
            <>
              <div className="hidden text-[14px] font-medium text-white ml:flex l:text-[16px]">
                {text}
              </div>
              <div className="flex text-[14px] font-medium text-white ml:hidden l:text-[16px]">
                {mobileText}
              </div>
            </>
          ) : (
            <div className="text-[14px] font-medium text-white l:text-[16px]">
              {text}
            </div>
          )}
        </div>
        <div className="hidden text-[14px] font-light text-white ml:flex l:text-[16px]">
          {subtext}
        </div>
        {cta && (
          <div
            role="button"
            className="hidden justify-end text-[14px] text-brandSecondary ml:flex ml:rounded-[8px] ml:border ml:border-white ml:px-[12px] ml:py-[8px] ml:font-light ml:text-white l:text-[16px]"
            onClick={onClickCta}
          >
            <div className="flex items-center">
              <span>{cta}</span>
              <ChevronRightIcon className="ml:hidden" width={20} height={20} />
            </div>
          </div>
        )}
        <div
          className="absolute right-[8px] top-[8px] ml:right-[16px] ml:top-1/2 ml:-translate-y-1/2"
          role="button"
          onClick={() => {
            setIsClosing(true);
            setIsVisible(false);
            sessionStorage.setItem('dismissedBanner', 'true');
          }}
        >
          <Icon
            width={24}
            height={24}
            iconId={IconIds.Cross}
            className="p-[4px]"
            strokeColor="#FFF"
            fillColor="#FFF"
          />
        </div>
      </div>
    </div>
  );
};
export { LandingPageBanner };
