import Image from '@/components/atoms/Image';
import ClientPortal from '@/components/molecules/ClientPortal';

interface AnnouncementModalProps {
  image: {
    src: string;
    width: number;
    height: number;
    alt: string;
  };
  open: boolean;
  onClose: () => void;
  text: string;
  subtext: string;
}

export const AnnouncementModal: React.FC<AnnouncementModalProps> = ({
  open,
  image,
  onClose,
  text,
  subtext,
}) => {
  return (
    <ClientPortal
      className="w-4/5 max-w-[640px] rounded-[8px] bg-white"
      open={open}
      closeFn={onClose}
    >
      <div className="flex flex-col gap-[16px] p-[24px] pt-[64px]">
        <Image
          className="w-full rounded-[8px]"
          disableLoadingGif
          src={image.src}
          alt={image.alt}
          width={image.width}
          height={image.height}
        />
        <div className="text-[32px] font-semibold leading-10">{text}</div>
        <div className="text-[20px] leading-[26px]">{subtext}</div>
      </div>
    </ClientPortal>
  );
};
