import { FC, useState } from 'react';
import clsx from 'clsx';
import Icon, { IconId } from '../legacy/Icon';

export enum IconBodyType {
  Sedan = 'Sedan',
  Hatchback = 'Hatchback',
  Coupe = 'Coupe',
  Luxury = 'Luxury',
  Suv = 'Suv',
  Truck = 'Truck',
  Minivan = 'Minivan',
  Wagon = 'Wagon',
  Van = 'Van',
}

export interface BodyTypeIconProps {
  bodyType: IconBodyType;
  'aria-label': string;
  onClick?: () => void;
  label?: string;
}

export const BodyTypeIcon: FC<BodyTypeIconProps> = ({
  bodyType,
  'aria-label': ariaLabel,
  onClick,
  label,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className={clsx('flex flex-col', {
        'cursor-pointer': onClick,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label={ariaLabel}
      onClick={onClick}
    >
      <div className="relative flex">
        <Icon iconId={bodyType as IconId} />
        <div
          className={clsx('absolute transition-opacity duration-250', {
            'opacity-0': !isHovered,
            'opacity-100': isHovered,
          })}
        >
          <Icon iconId={`${bodyType}Solid` as IconId} />
        </div>
      </div>
      {label && (
        <div className="flex w-full justify-center pt-s text-body1Medium text-neutral-800">
          {label}
        </div>
      )}
    </div>
  );
};
