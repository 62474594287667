import { CompareCTACard } from '@/components/molecules/CompareCTACard/CompareCTACard';
import { IncentivesCardHomepage } from '@/components/molecules/IncentivesCardHomepage/IncentivesCardHomepage';
import React, { FC } from 'react';

export const HomePageCompareCarousel: FC = () => {
  return (
    <section className="flex w-full flex-col gap-l py-[64px] m:flex-row">
      <div className={'flex w-full m:w-1/2'}>
        <CompareCTACard variant="VehicleFinder" />
      </div>

      <div className="flex w-full m:w-1/2">
        <IncentivesCardHomepage />
      </div>
    </section>
  );
};
